// Constants
import { REFERRALS_STATE, URL_APP_HORECA } from '@/constants'
// Components
import CardContainer from '@/components/ui/CardContainer'
import DataColumns from '@/components/ui/DataColumns'
import ReferralsPlacesItems from '@/components/elements/company/ReferralsPlacesItems'
import ShareSSNNLinks from '@/components/ui/ShareSSNNLinks'
import VuetifyDataTable from '@/components/ui/VuetifyDataTable'
import VuetifyToolBar from '@/components/ui/VuetifyToolBar'
// Mixins
import uiMixin from '@/mixins/uiMixin'
// Services
import { getManagedPlacesByReferredId } from '@/services/reffered'
// Vuex
import { mapGetters } from 'vuex'
// Filters
import { formatDate, formatPrice } from '@/filters'
// Utils
import { get, isNil } from 'lodash'

export default {
  name: 'ReferrerLink',
  components: {
    CardContainer,
    DataColumns,
    ReferralsPlacesItems,
    ShareSSNNLinks,
    VuetifyDataTable,
    VuetifyToolBar
  },
  mixins: [uiMixin],
  data() {
    return {
      // Table
      headers: [
        {
          text: 'Establecimiento',
          value: 'name'
        },
        {
          text: 'Fecha',
          value: 'date'
        },
        {
          text: 'Comisión',
          value: 'commission'
        },
        {
          text: 'Estado',
          value: 'status'
        }
      ],
      // Others
      referrals: [],
      referralStatistics: [],
      processingRequest: false
    }
  },
  computed: {
    ...mapGetters('company', ['companyData']),
    /**
     * Obtenemos el enlace de referencia
     *
     * @return {String}
     */
    referrerLink() {
      return `${URL_APP_HORECA}ref/${this.companyData.id}`
    }
  },
  async mounted() {
    await this.getEveryNeededData()
  },
  methods: {
    /**
     * Obtiene todos los datos necesarios para la vista
     */
    async getEveryNeededData() {
      await this.setEveryReferredData()
    },
    /**
     * Obtenemos los "referidos" por el establecimiento y tratamos
     * los datos para mostrar en la vista
     */
    async setEveryReferredData() {
      this.processingRequest = true
      try {
        // Establecimientos referidos
        const referrals = await getManagedPlacesByReferredId(this.companyData.id)

        if (!isNil(referrals) && referrals.length) {
          // Datos para las estadísticas
          const totalRef = referrals.length
          const totalRefActivated = referrals.reduce((sumRef, ref) => {
            if (ref.parentCleared) {
              sumRef += 1
            }
            return sumRef
          }, 0)
          const totalCommission = referrals.reduce((sumCommission, ref) => {
            if (ref.parentCleared) {
              sumCommission += get(ref, 'commission', 10)
            }
            return sumCommission
          }, 0)

          this.referralStatistics = [
            {
              label: 'Activados/Total',
              value: `${totalRefActivated}/${totalRef}`
            },
            {
              label: 'Total ganancias',
              value: formatPrice(totalCommission)
            }
          ]

          // Datos para la tabla
          this.referrals = referrals.map((ref) => {
            return {
              name: ref.placeName || 'Sin nombre',
              date: formatDate(ref.createTimestamp, 'DD/MM/YYYY'),
              commission: formatPrice(ref.commission),
              status: ref.parentCleared
                ? REFERRALS_STATE.activated.value
                : REFERRALS_STATE.pending.value
            }
          })
        }
      } catch (error) {
        this.modifyAppAlert({
          text: error.message,
          type: 'error',
          visible: true
        })
      } finally {
        this.processingRequest = false
      }
    }
  }
}
