import { referralStatusColor, referralStatusLabel } from '@/filters'

export default {
  name: 'ReferralsPlacesItems',
  filters: { referralStatusColor, referralStatusLabel },
  props: {
    items: {
      required: true,
      type: Array,
      default() {
        return []
      }
    },
    loading: {
      required: true,
      type: Boolean,
      default: true
    }
  }
}
